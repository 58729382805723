import React from 'react';

import {FAQ, PRACTICE_FAQ} from 'core/constants';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';

import {
    CollapsePanelV2,
    SectionContentWrapperV2,
    SectionV2,
} from '@HealthShareAU/hs-component-library';

import {observer} from 'mobx-react';

export default
@observer
class PracticeFAQV2 extends React.Component {
    static contextType = PracticeStoreContextV2;

    render() {
        if (!this.context.practiceFAQs?.length) {
            return null;
        }
        return (
            <SectionV2
                customClass="practice-faq-v2"
                contentClass="v2-section-layout"
                header="Frequently asked questions"
                sectionType="regular"
            >
                <SectionContentWrapperV2 customClass="practice-faq-answers-v2">
                    {this.context.practiceFAQs.map((faq) => {
                        return (
                            <CollapsePanelV2
                                dataTestId={FAQ}
                                key={faq.id}
                                gaObjectType={PRACTICE_FAQ}
                                gaSelector={FAQ}
                                header={faq.question}
                                htmlContent={faq.answer}
                                customClass={'accordion'}
                            />
                        );
                    })}
                </SectionContentWrapperV2>
            </SectionV2>
        );
    }
}
